import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Formik } from 'formik';
import { object, string, number } from 'yup';
import axios from 'axios';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import Container from '@bit/medicalwebexperts.mwe-ui.container';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Form from '@bit/medicalwebexperts.mwe-ui.form';
import Alert from '@bit/medicalwebexperts.mwe-ui.alert';
import { parseTrackingCookie } from '../../TrafficSource/utils';
import Link from '../../Link/Link';
import Button from '../../Button/Button';
import Field from '../../Form/Field';

const Wrapper = styled.div(
  css({
    bg: 'gray.200',
    borderTop: '1px',
    borderColor: 'blackAlpha.200',
    py: 5,
  }),
);

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  country: '',
  phone: '',
  state_field: '',
  age: '',
  comment: '',
  custom_fields: {
    offers: {
      chr: '',
      fnc: '',
      foundation: '',
      wmf: '',
    },
  },
};

const validations = object({
  first_name: string().required().label('First Name'),
  last_name: string().required().label('Last Name'),
  email: string().email().required().label('Email'),
  country: object({
    value: string().required().label('Country'),
  }),
  phone: string().required().label('Phone Number'),
  state_field: object().when('country', (country, schema) => {
    return country?.isoCode === 'US'
      ? schema.shape({
          value: string().required().label('State'),
        })
      : schema.notRequired();
  }),
  age: number().required().label('Age'),
});

const ContactUs = () => {
  const {
    allCountry,
    allState,
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query {
        allCountry {
          edges {
            node {
              externalId
              name
              isoCode
              continent
            }
          }
        }
        allState {
          edges {
            node {
              externalId
              name
            }
          }
        }
        site {
          siteMetadata {
            patientPortalURL
          }
        }
      }
    `,
  );
  const [error, setError] = useState(false);

  const countries = allCountry.edges.map(({ node }) => ({
    value: node.externalId,
    label: node.name,
    isoCode: node.isoCode,
    continent: node.continent,
  }));

  const states = allState.edges.map(({ node }) => ({
    value: node.externalId,
    label: node.name,
  }));

  const onSubmit = async (values) => {
    const tracking = parseTrackingCookie('traffic_source');
    const payload = {
      ...values,
      tracking,
      country: values.country.value,
      state_field: values.state_field?.value,
    };

    try {
      await axios.post(
        `${process.env.GATSBY_API_BASE_URL}/referral_contact`,
        payload,
      );

      window.location.href = '/getstarted/contactus-ty2';
    } catch (e) {
      setError(true);
    }
  };

  return (
    <Wrapper>
      <Container>
        <Typography variant="h2">Contact Us</Typography>
        <Typography variant="h4">
          You can also call 212-994-4400 to get in touch with us.
        </Typography>
        <Typography variant="h4">
          Want to donate your eggs?{' '}
          <Link to="/">Fill out the donor application first!</Link>
        </Typography>
        {error && (
          <Alert status="error" mb={4}>
            An account with this email address already exists in The CHR portal.
            Please click{' '}
            <Link to={siteMetadata.patientPortalURL} target="_blank">
              here
            </Link>{' '}
            to sign in. If you&apos;ve forgotten your password, click{' '}
            <Link
              to={`${siteMetadata.patientPortalURL}/#/forgot-password`}
              target="_blank"
            >
              here
            </Link>{' '}
            to create a new one.
          </Alert>
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={validations}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, isSubmitting, values }) => (
            <Form onSubmit={handleSubmit}>
              <Flex flexDirection={['column', null, null, 'row']}>
                <Flex.Box flex={1} pr={[0, null, null, 4]}>
                  <Field
                    name="first_name"
                    type="text"
                    placeholder="First Name *"
                    aria-label="First Name"
                  />
                  <Field
                    name="email"
                    type="email"
                    placeholder="Email *"
                    aria-label="Email"
                  />
                  <Field
                    name="phone"
                    type="tel"
                    placeholder="Phone Number *"
                    aria-label="Phone Number"
                  />
                  <Field
                    name="age"
                    type="number"
                    placeholder="Age (of female patient) *"
                    aria-label="Age (of female patient)"
                  />
                </Flex.Box>
                <Flex.Box flex={1} pl={[0, null, null, 4]}>
                  <Field
                    name="last_name"
                    type="text"
                    placeholder="Last Name *"
                    aria-label="Last Name"
                  />
                  <Field
                    name="country"
                    type="select"
                    placeholder="Country *"
                    aria-label="Country"
                    options={countries}
                  />
                  {values.country?.isoCode === 'US' && (
                    <Field
                      name="state_field"
                      type="select"
                      placeholder="State *"
                      aria-label="State"
                      options={states}
                    />
                  )}
                  <Field
                    name="comment"
                    type="textarea"
                    aria-label="Please tell us about the service you're interested in and your fertility history."
                    placeholder="Please tell us about the service you're interested in and your fertility history."
                    height={24}
                  />
                </Flex.Box>
              </Flex>
              {values.country?.continent === 'EU' && (
                <>
                  <Flex justifyContent="center">
                    <Flex.Box>
                      <Typography variant="body2">
                        <strong>
                          Check the boxes below to hear about new research,
                          clinical studies, programs, and special offers from
                          CHR and its affiliates.
                        </strong>
                      </Typography>
                    </Flex.Box>
                  </Flex>
                  <Flex justifyContent="space-around" my={4}>
                    <Flex.Box>
                      <Field
                        name="custom_fields.offers.chr"
                        type="checkbox"
                        aria-label="Center for Human Reproduction"
                        checkLabel="Center for Human Reproduction"
                      />
                    </Flex.Box>
                    <Flex.Box>
                      <Field
                        name="custom_fields.offers.fnc"
                        type="checkbox"
                        aria-label="Fertility Nutraceuticals"
                        checkLabel="Fertility Nutraceuticals"
                      />
                    </Flex.Box>
                    <Flex.Box>
                      <Field
                        name="custom_fields.offers.foundation"
                        type="checkbox"
                        aria-label="The Foundation for Reproductive Medicine"
                        checkLabel="The Foundation for Reproductive Medicine"
                      />
                    </Flex.Box>
                    <Flex.Box>
                      <Field
                        name="custom_fields.offers.wmf"
                        type="checkbox"
                        aria-label="What’s My Fertility?"
                        checkLabel="What’s My Fertility?"
                      />
                    </Flex.Box>
                  </Flex>
                </>
              )}
              <Flex justifyContent="center">
                <Flex.Box>
                  <Button
                    type="submit"
                    variantColor="primary"
                    loading={isSubmitting}
                  >
                    Submit
                  </Button>
                </Flex.Box>
              </Flex>
            </Form>
          )}
        </Formik>
      </Container>
    </Wrapper>
  );
};

export default ContactUs;
