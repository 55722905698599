/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import { number, shape } from 'prop-types';
import ReactPlayer from 'react-player/youtube';
import RatioBox from '@bit/medicalwebexperts.mwe-ui.ratio-box';

const propTypes = {
  ratio: number,
  ratioProps: shape({}),
};

const defaultProps = {
  ratio: 16 / 9,
  ratioProps: {},
};

const YoutubeVideo = ({ ratioProps, ratio, ...rest }) => (
  <RatioBox ratio={ratio} {...ratioProps}>
    <ReactPlayer {...rest} width="100%" height="100%" />
  </RatioBox>
);

YoutubeVideo.propTypes = propTypes;
YoutubeVideo.defaultProps = defaultProps;

export default YoutubeVideo;
