import React from 'react';
import { node, oneOf, string } from 'prop-types';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import css from '@styled-system/css';
import styled from '@emotion/styled';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import IconButton from '@bit/medicalwebexperts.mwe-ui.icon-button';

const propTypes = {
  children: node.isRequired,
  pagination: oneOf(['bottom', 'middle']),
  ariaLabel: string,
};

const defaultProps = {
  pagination: 'bottom',
  ariaLabel: null,
};

const Container = styled.div(
  css({
    bg: 'gray.200',
    position: 'relative',
  }),
);

const BottomPagination = () => (
  <Flex justifyContent="space-between" bg="gray.300">
    <IconButton
      as={ButtonBack}
      corner="square"
      variant="contained"
      variantColor="primary"
      size="sm"
    >
      <MdChevronLeft />
    </IconButton>
    <IconButton
      as={ButtonNext}
      corner="square"
      variant="contained"
      variantColor="primary"
      size="sm"
    >
      <MdChevronRight />
    </IconButton>
  </Flex>
);

const MiddleWrapper = styled.div(
  css({
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    left: '-52px',
    right: '-52px',
  }),
);

const MiddlePagination = () => (
  <MiddleWrapper>
    <IconButton as={ButtonBack} variantColor="secondary" size="lg">
      <MdChevronLeft size={48} />
    </IconButton>
    <IconButton as={ButtonNext} variantColor="secondary" size="lg">
      <MdChevronRight size={48} />
    </IconButton>
  </MiddleWrapper>
);

const Carousel = ({ children, pagination, ariaLabel, ...rest }) => (
  <CarouselProvider {...rest}>
    <Container>
      <Slider aria-label={ariaLabel}>{children}</Slider>
      {pagination === 'bottom' ? <BottomPagination /> : <MiddlePagination />}
    </Container>
  </CarouselProvider>
);

Carousel.propTypes = propTypes;
Carousel.defaultProps = defaultProps;

Carousel.Slide = Slide;

export default Carousel;
